<template>
    <div class="navbar-end">
        <div class="navbar-item">
          
<p style="padding-right: 17px"><b>Logged in as {{this.getUsername}}</b></p>
          <div class="buttons">
            <b-button type="is-light" @click="logout">Log out</b-button>
          </div>
        </div>
      </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NavBar',
  data() {
    return {
      shouldShow: false
    }
  },
  computed: mapGetters(['getUsername']),
  methods: {
    ...mapActions(['setUsername', 'setPassword', 'setAuthHeader']),
    logout() {
      this.setUsername(null)
      this.setPassword(null)
      this.setAuthHeader(null)
      localStorage.removeItem('loggedIn')
      // this.$session.setItem('isLoggedIn', false)
      this.$router.replace({ name: 'login' })
    }
    /* beforeUn() {
      console.log('before unnn')
      sessionStorage.setItem('isLoggedIn', false)
      console.log(sessionStorage.getItem('isLoggedIn'), 'is logged in')
    } */
  },
  mounted() {
    this.shouldShow = localStorage.getItem('loggedIn') ? true : false
    if (!this.shouldShow) {
      this.setUsername(null)
      this.setPassword(null)
      this.setAuthHeader(null)
    }
  },
  watch: {
    shouldShow() {
      this.shouldShow = localStorage.getItem('loggedIn') ? true : false
      if (!this.shouldShow) {
        this.setUsername(null)
        this.setPassword(null)
        this.setAuthHeader(null)
      }
    }
  }
}
</script>