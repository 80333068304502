import Vue from 'vue'
import Vuex from 'vuex'
import applications from './modules/applications'
import categories from './modules/categories'
import user from './modules/user'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    applications,
    categories,
    user
  },
  plugins: [createPersistedState()]
})
