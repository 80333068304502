import Vue from 'vue'
import VueRouter from 'vue-router'
import AppsView from '../views/AppsView.vue'
import CategoriesView from '../views/CategoriesView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/apps',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apps',
    name: 'apps',
    component: AppsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/categories',
    name: 'categories',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CategoriesView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
