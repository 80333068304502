<template>
    <div class="columns">
        <div class="column is-narrow">
            <v-list nav style="margin-top:0rem;background-color:#0434a0; border-radius: 5px; width: 235px;">
                <v-subheader style="color: white">MENU</v-subheader>
                <v-list-item v-for="item in items" :key="item.title" :to="item.to"
                    style="color: white; text-decoration: none;" link>

                    <v-list-item-icon>
                        <v-icon style="margin-right:0.8rem; color: white">{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title style="font-size:20px"><b>{{item.title }}</b></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item class="px-2">
                <v-btn :ripple="false" block icon @click="toggleMenu">
                <v-icon large v-if="mini">keyboard_arrow_right</v-icon>
                <v-icon large v-else>keyboard_arrow_left</v-icon>
                </v-btn>
              </v-list-item> -->

            </v-list>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SideBar',
    data() {
        return {
            expandOnHover: false,
            expandWithDelay: false,
            mobile: "reduce",
            reduce: false,
            items: [
                { title: 'Applications', icon: 'apps', to: '/apps' },
                { title: 'Categories', icon: 'category', to: '/categories' }
            ]
        };
    }
};
</script>

<style scoped>

</style>