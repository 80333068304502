<template>
    <section>
        <div class="container.is-widescreen" style="padding:18px">
            <div style="display: table; width: 100%; padding-bottom: 18px">
                <div style="display: table-cell; width: 35%; padding-right: 8px">
                    <b-input v-model="searchString" placeholder="Search..."></b-input>
                </div>
                <div style="display: table-cell;">
                    <b-dropdown :triggers=" ['hover']" aria-role="list" v-model="selectedCategory">
                        <template #trigger>
                            <b-button :label="dropdownLabel" icon-right="menu-down" />
                        </template>

                        <b-dropdown-item value="" style="color: #6d70f2; font-size: medium" @click="setDropdownCategory('All categories')">
                            All categories
                        </b-dropdown-item>
                        <b-dropdown-item v-for="category in this.allCategories" :key="category._id" :value="category._id" aria-role="listitem" 
                        style="color: #6d70f2; font-size: medium" @click="setDropdownCategory(category.name)">
                        {{category.name}}
                    </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div class="columns is-multiline is-mobile">
                <div class="column is-one-fifth" v-for="app in filteredApplications" :key="app._id">
                    <div class="card">
                        <!-- <header class="card-header">
      <p class="card-header-title" style="font-size: 25px">
        Instagram
      </p>
      <button class="card-header-icon" aria-label="more options" style="cursor: auto" disabled>
          Social, Marketing
      </button>
    </header> -->
                        <div class="card-content">
                            <div style="display: table; width: 100%;">

                                <div style="display: table-cell; width: 100%">

                                    <p class="card-header-title text-title" style="font-size: 25px; color:#5d5d5d">
                                        {{app.name}}
                                    </p>
                                </div>
                                <div style="display: table-cell;padding-right: 2px">
                                    <p>{{showRating(app.rating)}}</p>
                                </div>
                            </div>
                            <p style="padding-bottom: 9px">
                                {{getCategoryNames(app.categoriesIdList)}}
                            </p>

                        </div>

                        <div class="media">
                            <div class="media-left" style="max-width: 33%; max-height: 33%">
                                <figure class="image is-3by3">
                                    <img style="max-width: 130px; max-height: 130px; padding-left: 9px;"
                                        :src="app.logoImageUrl"
                                        alt="Placeholder image">
                                </figure>
                            </div>
                            <div class="media-content text" style="max-width: 67%; max-height: 33%; padding-right: 9px;">
                                {{app.desc}}
                            </div>
                        </div>
                        <footer class="card-footer">
                            <a href="#" class="card-footer-item" style="color: #0434a0" @click="editApp(app)">Edit</a>
                            <a href="#" class="card-footer-item" style="color: #0434a0" @click="deleteApp(app._id, app.name)">Delete</a>
                        </footer>
                    </div>
                </div>

            </div>
            <b-pagination
            :total="totalCount"
            v-model="current"
            :range-before="rangeBefore"
            :range-after="rangeAfter"
            :order="order"
            :size="size"
            :simple="isSimple"
            :rounded="isRounded"
            :per-page="perPage"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :page-input="hasInput"
            :page-input-position="inputPosition"
            :debounce-page-input="inputDebounce">
        </b-pagination>
        </div>
        <vue-fab v-if="!bAddApp" title="Add application" mainBtnColor="#0434a0" icon="add" size="big"
            @clickMainBtn="addApp" />
    </section>
</template>
  
<script>
import AppAddEdit from '@/components/AppAddEdit.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'AppsView',
    data: () => {
        return {
            bAddApp: false,
            selectedCategory: null,
            dropdownLabel: 'All categories',
            searchString: null,
            // total: null,
            current: 1,
            perPage: 10,
            rangeBefore: 1,
            rangeAfter: 1,
            order: 'is-centered',
            size: '',
            isSimple: false,
            isRounded: false,
            hasInput: false,
            prevIcon: 'chevron-left',
            nextIcon: 'chevron-right',
            inputPosition: '',
            inputDebounce: '',
            appsByCategory: []
        }
    },
    watch: {
        selectedCategory() {
            this.current = 1
            var limit = 0
            var y = null
            this.appsByCategory = []
            var firstArray = []
            if (this.searchString !== '' && this.searchString !== null) {
                for (let app in this.allApplications) {
                    if (this.allApplications[app].name.toUpperCase().includes(this.searchString.toUpperCase())) {
                        firstArray.push(this.allApplications[app])
                    }
                }
            } else {
                firstArray = Array.from(this.allApplications)
            }
            this.globalLogger('sel category changed')
            if (this.selectedCategory !== '') {
                this.globalLogger(firstArray, 'ALL')
                for (let z = 0; z < firstArray.length; z++) {
                    if (firstArray[z].categoriesIdList.includes(this.selectedCategory)) {
                        this.appsByCategory.push(firstArray[z])
                    }
                }
                this.setCount(this.appsByCategory.length)
                this.globalLogger('here', this.selectedCategory)
                // this.fetchApplicationsByCategory(this.selectedCategory)
                // this.globalLogger(this.applicationsByCategory, 'APPS BY CATEGORY')
                // this.totalCount = this.appsByCategory.length
                var arr = []
                // var x = 0
                y = this.perPage - 1
                if (y > (this.appsByCategory.length - 1)) {
                    limit = (this.appsByCategory.length - 1)
                } else {
                    limit = y
                }
                this.globalLogger('limit: ', limit)
                for (let i = 0; i <= limit; i++) {
                    arr.push(this.appsByCategory[i])
                }
                this.globalLogger('arr', arr)
                this.setFilteredApps(arr)
            } else {
                // this.fetchApplications()
                this.setCount(firstArray.length)
                var startArray = []
                var x = (this.current - 1)*this.perPage
                y = x + this.perPage - 1
                if (y > (firstArray.length - 1)) {
                    limit = (firstArray.length - 1)
                } else {
                    limit = y
                }
                for (let i = x; i <= limit; i++) {
                    startArray.push(firstArray[i])
                }
                this.globalLogger('222')
                this.setFilteredApps(startArray)
            }
        },
        searchString() {
            this.current = 1
            var firstArray = []
            var searchArray = []
            if (this.selectedCategory !== '' && this.selectedCategory !== null) {
                for (let z = 0; z < this.allApplications.length; z++) {
                    if (this.allApplications[z].categoriesIdList.includes(this.selectedCategory)) {
                        firstArray.push(this.allApplications[z])
                    }
                }
            } else {
                firstArray = Array.from(this.allApplications)
            }
            for (let app in firstArray) {
                if (firstArray[app].name.toUpperCase().includes(this.searchString.toUpperCase())) {
                    searchArray.push(firstArray[app])
                }
            }
            this.setCount(searchArray.length)
            var arr = []
            // var x = 0
            var y = this.perPage - 1
            var limit = 0
            if (y > (searchArray.length - 1)) {
                limit = (searchArray.length - 1)
            } else {
                limit = y
            }
            for (let i = 0; i <= limit; i++) {
                arr.push(searchArray[i])
            }
            this.globalLogger('arr', arr)
            this.setFilteredApps(arr)
        },
        current() {
            this.appsByCategory = []
            if(this.selectedCategory !== '' && this.selectedCategory !== null) {
                this.globalLogger(this.appsByCategory, 'apps')
                for (let z = 0; z < this.allApplications.length; z++) {
                    if (this.allApplications[z].categoriesIdList.includes(this.selectedCategory)) {
                        this.appsByCategory.push(this.allApplications[z])
                    }
                }
            }
            var filtrdArray = []
            var x = (this.current - 1)*this.perPage
            var y = x + this.perPage - 1
            var limit = 0
            if (this.searchString !== '' && this.searchString !== null && this.selectedCategory !== '' && this.selectedCategory !== null) {
                var searchCatArray = []
                for (let app in this.appsByCategory) {
                    if (this.appsByCategory[app].name.toUpperCase().includes(this.searchString.toUpperCase())) {
                        searchCatArray.push(this.appsByCategory[app])
                    }
                }
                if (y > (searchCatArray.length - 1)) {
                    limit = (searchCatArray.length - 1)
                } else {
                    limit = y
                }
                for (let i = x; i <= limit; i++) {
                    filtrdArray.push(searchCatArray[i])
                }
            } else if (this.searchString !== '' && this.searchString !== null) {
                var searchArray = []
                for (let app in this.allApplications) {
                    if (this.allApplications[app].name.toUpperCase().includes(this.searchString.toUpperCase())) {
                        searchArray.push(this.allApplications[app])
                    }
                }
                if (y > (searchArray.length - 1)) {
                    limit = (searchArray.length - 1)
                } else {
                    limit = y
                }
                for (let i = x; i <= limit; i++) {
                    filtrdArray.push(searchArray[i])
                }
            } else if (this.selectedCategory !== '' && this.selectedCategory !== null) {
                // this.fetchApplicationsByCategory(this.selectedCategory)
                // this.total = this.applicationsByCategory.length
                if (y > (this.appsByCategory.length - 1)) {
                    limit = (this.appsByCategory.length - 1)
                } else {
                    limit = y
                }
                for (let i = x; i <= limit; i++) {
                    filtrdArray.push(this.appsByCategory[i])
                }
            } else {
                if (y > (this.allApplications.length - 1)) {
                    limit = (this.allApplications.length - 1)
                } else {
                    limit = y
                }
                for (let i = x; i <= limit; i++) {
                    filtrdArray.push(this.allApplications[i])
                }
            }
            this.setFilteredApps(filtrdArray)
        }
    },
    mounted() {
        this.fetchApplications()
        this.fetchCategories()
        this.setCount(this.allApplications.length)
        var startArray = []
        var x = (this.current - 1)*this.perPage
        var y = x + this.perPage - 1
        var limit = 0
        if (y > (this.allApplications.length - 1)) {
            limit = (this.allApplications.length - 1)
        } else {
            limit = y
        }
        for (let i = x; i <= limit; i++) {
            startArray.push(this.allApplications[i])
        }
        this.globalLogger('startArray', startArray)
        this.setFilteredApps(startArray)
    },
    computed: mapGetters(['filteredApplications', 'allApplications', 'allCategories', 'totalCount', 'getUsername', 'getPassword']),
    methods: {
        ...mapActions(['fetchApplications', 'fetchCategories', 'deleteApplication', 'setFilteredApps', 'setCount', 'setStartIndex']),
        addApp() {
            this.bAddApp = true
            this.$buefy.modal.open({
                component: AppAddEdit,
                parent: this,
                props: { editEnabled: false, selectedCat: this.selectedCategory, searchStr: this.searchString },
                hasModalCard: true,
                trapFocus: true,
                canCancel: ['x'],
                events: {
                    'close': value => {
                        this.globalLogger(value)
                        this.bAddApp = false
                    },
                    'setCategory': value => {
                        this.globalLogger(value)
                        this.selectedCategory = ''
                        this.globalLogger('HERE 2')
                        this.setDropdownCategory('All categories')
                    },
                    'setSearchStr': value => {
                        this.globalLogger(value)
                        this.searchString = ''
                    }
                }
            })
        },
        editApp(app) {
            this.bAddApp = true
            this.$buefy.modal.open({
                component: AppAddEdit,
                parent: this,
                props: { editEnabled: true, appToEdit: app },
                hasModalCard: true,
                trapFocus: true,
                canCancel: ['x'],
                events: {
                    'close': value => {
                        this.globalLogger(value)
                        this.bAddApp = false
                    }
                }
            })
        },
        getCategoryNames(categoriesList) {
            var name = null
            var namesList = []
            for (let catId in categoriesList) {
                name = this.$store.getters.categoryName(categoriesList[catId])
                namesList.push(name)
            }
            return namesList.join(', ')
        },
        deleteApp(id, name) {
            this.globalLogger(this.filteredApplications, 'filtred[0]')
            this.$buefy.dialog.confirm({
                message: 'Are you sure that you want to <b>delete</b> app "' + name + '"?',
                confirmText: 'Yes',
                type: 'is-danger',
                onConfirm: () => {
                    /* var index = this.allApplications.findIndex((app) => app === this.filteredApplications[0])
                    if (index === -1) {
                        index = 0
                    }
                    this.setStartIndex(index) */
                    this.setDropdownCategory('All categories')
                    this.selectedCategory = ''
                    this.current = 1
                    this.searchString = ''
                    this.globalLogger(this.filteredApplications, 'fa 1')
                    this.deleteApplication(id)
                }
            })
        },
        setDropdownCategory(name) {
            this.dropdownLabel = name
        },
        showRating(r) {
            return r.toFixed(2)
        }
    }
}
</script>

<style scoped>
    .text {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
.text-title {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
</style>