<template>
    <section>
        <div class="container.is-widescreen" style="padding:18px">
<div style="width: 35%">
    <b-table :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :pagination-rounded="isPaginationRounded"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :pagination-order="paginationOrder"
            :mobile-cards="false" :data="allCategories.length > 0 ? allCategories : []" striped>

          <!-- <b-table-column field="number" centered  v-slot="props">
            {{parseInt(props.row.id)+1}}
          </b-table-column> -->
          <b-table-column field="name" label="Name" centered  v-slot="props">
            {{props.row.name}}
          </b-table-column>
          <b-table-column field="name" label="Background image" centered v-slot="props">

            <div v-if="props.row.backgroundUrl">
              <b-modal :active.sync="isBackgroundModalActiveCat" scroll="keep" @close="bAddCat=false">
        <p class="image">
          <img width="500" :src="resourceUrl" :title="props.row.backgroundUrl.split('/')[props.row.backgroundUrl.split('/').length-1]">
        </p>
        <p>{{imageWidth}}x{{imageHeight}}</p>
      </b-modal>
              <!-- <a style="color: #3e69cd" @click="isBackgroundModalActiveCat=true; bAddCat=true;">{{(props.row.backgroundUrl.split('/')[props.row.backgroundUrl.split('/').length-1]).slice(0, 20)}}</a> -->
              <div style="cursor: pointer" @click="showBackground(props.row.backgroundUrl)"><img src="../assets/checkmark.png" 
            width="25" :title="props.row.backgroundUrl.split('/')[props.row.backgroundUrl.split('/').length-1]"/></div>
            </div>
            <div v-else>
              <p>None</p>
            </div>

          </b-table-column>
          <b-table-column label="Edit" v-slot="props" centered>
            <!-- <a style="color: #6d70f2" @click="editCat(props.row)">Edit</a>-->
            <div @click="editCat(props.row)" style="cursor: pointer"><img src="../assets/edit.png" 
            width="25"/></div>
          </b-table-column>
          <b-table-column label="Delete" centered v-slot="props">
            <div @click="deleteCat(props.row._id, props.row.name)" style="cursor: pointer"><img src="../assets/delete.png" 
            width="25"/></div>
          </b-table-column>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No items to display.</p>
            </div>
          </section>
        </template>
      </b-table>

</div>
        </div>
        <vue-fab v-if="!bAddCat" title="Add category" mainBtnColor="#0434a0" icon="add" size="big"
            @clickMainBtn="addCategory" />
        </section>
</template>

<script>
    import CategoryAddEdit from '@/components/CategoryAddEdit.vue'
    // import axios from 'axios'
    import { mapGetters, mapActions } from 'vuex'
    // import { DialogProgrammatic as Dialog } from 'buefy'

    export default {
        name: 'CategoriesView',
        data: () => {
            return {
                bAddCat: false,
                isPaginated: true,
                isPaginationSimple: true,
                isPaginationRounded: false,
                paginationPosition: 'bottom',
                defaultSortDirection: 'asc',
                currentPage: 1,
                perPage: 12,
                paginationOrder: '',
                isBackgroundModalActiveCat: false,
                resourceUrl: null,
                imageWidth: null,
                imageHeight: null
                // rowsPerPage: 10
            }
        },
        computed: mapGetters(['allCategories', 'appsByCat']),
        created() {
            this.fetchCategories()
        },
        methods: {
            ...mapActions(['fetchCategories', 'addCategory', 'deleteCategory', 'fetchAppsByCategory']),
            addCategory() {
                this.bAddCat = true
                this.$buefy.modal.open({
                    component: CategoryAddEdit,
                    parent: this,
                    props: { editEnabled: false },
                    hasModalCard: true,
                    trapFocus: true,
                    canCancel: ['x'],
                    events: {
                        'close': value => {
                            this.globalLogger(value)
                            this.bAddCat = false
                        }
                    }
                })
            },
            editCat(cat) {
                this.bAddCat = true
                this.$buefy.modal.open({
                    component: CategoryAddEdit,
                    parent: this,
                    props: { editEnabled: true, categoryToEdit: cat },
                    hasModalCard: true,
                    trapFocus: true,
                    canCancel: ['x'],
                    events: {
                        'close': value => {
                            this.globalLogger(value)
                            this.bAddCat = false
                        }
                    }
                })
            },
            deleteCat(id, name) {
                this.fetchAppsByCategory(id)
                // this.globalLogger(this.$store.state.user.authHeader, 'header')
                this.$buefy.dialog.confirm({
                    message: 'Are you sure that you want to <b>delete</b> category "' + name + '"?',
                    confirmText: 'Yes',
                    type: 'is-danger',
                    onConfirm: () => {
                      this.fetchAppsByCategory(id)
                      this.globalLogger(this.appsByCat, 'tuka')
                      if (this.appsByCat.length > 0) {
                        this.$buefy.dialog.alert({
                          title: 'Invalid action',
                          message: 'Category "' + name + '" cannot be deleted because there are applications that use it.',
                          confirmText: 'Ok!',
                          type: 'is-danger',
                          hasIcon: true,
                          icon: 'times-circle',
                          iconPack: 'fa'
                        })
                      } else {
                        this.deleteCategory(id)
                      }
                    }
                })
            },
            showBackground(url) {
              this.isBackgroundModalActiveCat = true
              this.bAddCat=true
              this.resourceUrl=url
              let img = new Image()
              img.src = url
              img.onload = () => {
                this.imageWidth = img.width
                this.imageHeight = img.height
              }
            }
        }
    }
</script>

<style scoped>
    .md-table-head {
        font-size: large;
        text-align: center;
    }
    .md-table-cell {
        font-size: medium;
        text-align: center;
        vertical-align: middle;
    }
</style>