<template>
    <div class="modal-card" ref="element" style="width: 500px">
        <!--style="width: auto">-->
        <header class="modal-card-head">
            <p class="modal-card-title">{{editEnabled ? "Edit category" : "Add category"}}</p>
        </header>
        <section class="modal-card-body">
            <b-field label="Name:">
                <b-input v-model="name" placeholder="Enter a name..."></b-input>
            </b-field>

            <b-field v-if="bNameError" style="color: red">
                Please enter a name.
            </b-field>

            <b-field label="Background image:">
            </b-field>
            <div v-if="editEnabled && sourceImage !== ''" class="image"> <!-- + uslov: ima slika -->
                <img :src="sourceImage" alt="imgg">
                <div class="content"><p>{{resourceName}}</p></div>
                <div class="content-bottom" v-if="showRemoveBcg"><p style="float: right"><img src="../assets/deleteicon.png" style="width: 33px; cursor: pointer; padding-bottom: 3px; padding-right: 3px" @click="sourceImage=''"></p></div>
                <!-- <p v-if="showRemoveBcg" style="color: red; cursor: pointer; float: right" @click="sourceImage=''">Remove background image</p>-->
            </div>

            <b-field>
                <input style="padding-top: 10px" type="file" name="imageSelect" id="imageSelect" @change="imageUploaded">
            </b-field>

            <b-field v-if="imageError" style="color: red">
                Incompatible file format! The supported types are .jpg and .png.
            </b-field>
            
        </section>
        <footer class="modal-card-foot">
            <b-button v-if="editEnabled" @click="editCat">Submit</b-button>
            <b-button v-else icon-left="plus" @click="addCat">Add category</b-button>
            <b-button type="is-light" @click="$emit('close')">Close</b-button>
        </footer>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'CategoryAddEdit',
    props: ['editEnabled', 'categoryToEdit'],
    data: () => {
        return {
            bNameError: false,
            imageError: false,
            name: null,
            backgroundFile: null,
            sourceImage: null,
            resourceName: null,
            showRemoveBcg: true,
            fileToCheck: null
        }
    },
    mounted() {
        if (this.editEnabled) {
            this.name = this.categoryToEdit.name
            this.sourceImage = this.categoryToEdit.backgroundUrl
            var array = this.sourceImage.split('/')
            this.resourceName = array[array.length-1]
            this.globalLogger('source image: ', this.sourceImage)
        }
    },
    methods: {
        ...mapActions(['addCategory', 'editCategory', 'uploadBackgroundCategory']),
        addCat () {
            this.bNameError = false
            this.imageError = false
            if (this.name === null || this.name === '') {
                this.bNameError = true
            } else if (this.fileToCheck !== null && this.fileToCheck.type !== 'image/jpeg' && this.fileToCheck.type !== 'image/png') {
                this.imageError = true
            } else {
                let payload = {
                    name: this.name
                }
                if (this.backgroundFile !== null) {
                    payload.formData = this.backgroundFile
                    this.backgroundFile = null
                }
                this.addCategory(payload)
                this.$emit('close')
            }
        },
        editCat () {
            this.bNameError = false
            this.imageError = false
            if (this.name === null || this.name === '') {
                this.bNameError = true
            } else if (this.fileToCheck !== null && this.fileToCheck.type !== 'image/jpeg' && this.fileToCheck.type !== 'image/png') {
                this.imageError = true
            } else {
                var payload = {
                    _id: this.categoryToEdit._id,
                    name: this.name,
                    backgroundUrl: this.sourceImage
                }
                this.globalLogger(payload, 'pl')
                if (this.backgroundFile !== null) {
                    payload.formData = this.backgroundFile
                    this.backgroundFile = null
                }
                this.editCategory(payload)
                this.$emit('close')
            }
        },
        imageUploaded (e) {
            // const FormData = require('form-data')
            let file = e.target.files[0]
            this.fileToCheck = file
            const formData = new FormData()
            formData.append('file', file)
            this.globalLogger(formData, 'background file')
            this.backgroundFile = formData
            this.showRemoveBcg = false
        }
    }
}
</script>

<style scoped>
.content {
  position: absolute; /* Position the background text */
  top: 0; /* At the bottom. Use top:0 to append it to the top */
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.15); /* Black background with 0.5 opacity */
  color: #f1f1f1; /* Grey text */
  width: 100%; /* Full width */
  padding: 3px; /* Some padding */
}
.content-bottom {
  position: absolute; /* Position the background text */
  bottom: 0; /* At the bottom. Use top:0 to append it to the top */
  color: #f1f1f1; /* Grey text */
  width: 100%; /* Full width */
  padding: 3px; /* Some padding */
}
</style>