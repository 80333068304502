<template>

    <div class="modal-card" ref="element">
        <!--style="width: auto">-->
        <header class="modal-card-head">
            <p class="modal-card-title">{{editEnabled ? "Edit application" : "Add application"}}</p>
        </header>
        <section class="modal-card-body">
            <v-app class="v-appl">
            <b-field label="Name:">
                <b-input v-model="name" placeholder="Enter a name..."></b-input>
            </b-field>

            <b-field v-if="bNameError" style="color: red">
                Please enter a name.
            </b-field>

            <b-field label="Description:">
                <b-input maxlength="1000" type="textarea" v-model="description" placeholder="Enter a description...">
                </b-input>
            </b-field>

            <b-field v-if="descError" style="color: red">
                Please enter a description.
            </b-field>

            <b-field label="Categories:">
                <fieldset>
                    <label v-for="category in this.allCategories" :key="category._id" class="container">{{category.name}}
                        <input type="checkbox" v-model="checkedCategories" :id="category.name" :value="category._id">
                        <span class="checkmark"></span>
                    </label>
                </fieldset>
            </b-field>

            <b-field v-if="categoriesError" style="color: red">
                Please select at least one category.
            </b-field>

            <b-field label="Package name:">
                <b-input v-model="packageName" placeholder="Enter package name..."></b-input>
            </b-field>

            <b-field v-if="pnError" style="color: red">
                Please enter a package name.
            </b-field>

            <b-field label="App version:">
                <b-numberinput v-model="appVersion" step="0.1" min="1.0"></b-numberinput>
            </b-field>

            <b-field v-if="!editEnabled" label="Apk file:">
                <!--<input type="file" name="apkSelect" id="apkSelect" @change="apkUploaded">-->
                <b-button icon-left="file-upload" type="is-primary is-small" outlined @click="addApk()">
          Choose file
        </b-button>
        <b-field v-if="apkToCheck">
        Chosen file for upload: {{apkToCheck.name}}
      </b-field>
            </b-field>

            <div v-else>
                <b-field label="Apk file:">
                    <p>Current file: <a style="color: #3e69cd" :href="apkUrl">{{apkName}}</a></p>
                </b-field>
                <b-field>
                    <b-button icon-left="file-upload" type="is-primary is-small" outlined @click="addApk()">
          Choose file
        </b-button>
        <b-field v-if="apkToCheck">
        Chosen file for upload: {{apkToCheck.name}}
      </b-field>

               <!--<input type="file" name="apkSelect" id="apkSelect" @change="apkUploaded">-->
                </b-field>
            </div>
<b-field></b-field>

<b-field v-if="apkError" style="color: red">
                {{apkMessage}}
            </b-field>

            <b-field v-if="!editEnabled" label="Logo image:">
                <!--<input type="file" name="logoSelect" id="logoSelect" @change="logoUploaded">-->
                <b-button icon-left="file-upload" type="is-primary is-small" outlined @click="addLogo()">
          Choose file
        </b-button>
        <b-field v-if="logoToCheck">
        Chosen file for upload: {{logoToCheck.name}}
      </b-field>
            </b-field>

            <div  v-else>
            <b-field label="Logo image:">
                <p>Current file: <a style="color: #3e69cd" @click="isLogoModalActive=true">{{logoName}}</a></p>
            </b-field>
            <b-field>
                <!--<input type="file" name="logoSelect" id="logoSelect" @change="logoUploaded">-->
                <b-button icon-left="file-upload" type="is-primary is-small" outlined @click="addLogo()">
          Choose file
        </b-button>
        <b-field v-if="logoToCheck">
        Chosen file for upload: {{logoToCheck.name}}
      </b-field>
            </b-field>
            <b-modal :active.sync="isLogoModalActive" scroll="keep">
        <p class="image">
          <img width="500" :src="logoUrl">
        </p>
      </b-modal>
        </div>
            <b-field>
                
            </b-field>

            <b-field v-if="logoError" style="color: red">
                {{logoMessage}}
            </b-field>

            <b-field v-if="!editEnabled" label="Background image:">
                <!--<input type="file" name="backgroundSelect" id="backgroundSelect" @change="backgroundUploaded">-->
                <b-button icon-left="file-upload" type="is-primary is-small" outlined @click="addBcg()">
          Choose file
        </b-button>
        <b-field v-if="bcgToCheck">
        Chosen file for upload: {{bcgToCheck.name}}
      </b-field>
            </b-field>

            <div  v-else>
            <b-field label="Background image:">
                <p>Current file: <a style="color: #3e69cd" @click="isBackgroundModalActive=true">{{bcgName}}</a></p>
            </b-field>
            <b-field>
                <!--<input type="file" name="backgroundSelect" id="backgroundSelect" @change="backgroundUploaded">-->
                <b-button icon-left="file-upload" type="is-primary is-small" outlined @click="addBcg()">
          Choose file
        </b-button>
        <b-field v-if="bcgToCheck">
        Chosen file for upload: {{bcgToCheck.name}}
      </b-field>
            </b-field>
            <b-modal :active.sync="isBackgroundModalActive" scroll="keep">
        <p class="image">
          <img width="500" :src="bcgUrl">
        </p>
      </b-modal>
        </div>
<b-field></b-field>

<b-field v-if="bcgError" style="color: red">
{{backgroundMessage}}
</b-field>

<div v-if="editEnabled && screenshotsByApp.length > 0">
            <b-field label="Application screenshots:">
                <!-- <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li v-for="(item, i) in screenshotsByApp" :key="item._id" data-target="#carouselExampleIndicators" :data-slide-to="i" :class="i===0 ? 'active': ''"></li>
  </ol>
  <div class="carousel-inner">
    <div v-for="(item, j) in screenshotsByApp" :key="item._id" :class="j===0 ? 'carousel-item active' : 'carousel-item'">
      <div>
        <img :title="item.name" class="d-block w-100" :src="item.imageUrl">
        <div class="content" @click="removeScreenshot(item._id)" style="text-align: center;"><img src="../assets/deleteicon.png" width="35" style="padding-bottom: 32px; cursor: pointer" ></div>
    </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div> -->
<v-carousel  :show-arrows="screenshotsNumber > 1 ? true : false" height="350">
    <v-carousel-item
      v-for="(scr,i) in screenshotsByApp"
      :key="i"
      :src="scr.imageUrl"
    >
    <div class="content" @click="removeScreenshot(scr._id)" style="text-align: center;"><img src="../assets/deleteicon.png" width="35" style="padding-bottom: 50px; cursor: pointer" title="Remove screenshot"></div>
</v-carousel-item>
  </v-carousel>

            </b-field>
            <b-field>
                <input type="file" name="imagesArray" @change="screenshotsUploaded" multiple>
            </b-field>
        </div>
            <b-field v-else label="Application screenshots:">
                <input type="file" name="imagesArray" @change="screenshotsUploaded" multiple>
            </b-field>
            <b-field v-if="scrError" style="color: red">
                Incompatible file format! The supported types are .jpg and .png.</b-field>
</v-app>
        </section>
        <footer class="modal-card-foot">
            <b-button v-if="editEnabled" @click="editApp">Submit</b-button>
            <b-button v-else icon-left="plus" @click="addApp">Add application</b-button>
            <b-button type="is-light" @click="$emit('close')">Close</b-button>
            <input style="display: none" type="file" name="apkSelect" id="apkSelect" @change="apkUploaded">
            <input style="display: none" type="file" name="logoSelect" id="logoSelect" @change="logoUploaded">
            <input style="display: none" type="file" name="backgroundSelect" id="backgroundSelect" @change="backgroundUploaded">
        </footer>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'AppAddEdit',
    props: ['editEnabled', 'appToEdit', 'selectedCat', 'searchStr'],
    data: () => {
        return {
            bNameError: false,
            name: null,
            description: null,
            descError: false,
            packageName: null,
            appVersion: 1.0,
            checkedCategories: [],
            categoriesError: false,
            pnError: false,
            apkError: false,
            logoError: false,
            bcgError: false,
            scrError: false,
            apkFile: null,
            logoFile: null,
            backgroundFile: null,
            logoName: null,
            isLogoModalActive: false,
            bcgName: null,
            apkName: null,
            isBackgroundModalActive: false,
            logoUrl: null,
            bcgUrl: null,
            apkUrl: null,
            screenshotsFiles: null,
            fetchedScreenshots: null,
            screenshotsToRemove: [],
            screenshotsToAdd: [],
            apkMessage: '',
            logoMessage: '',
            backgroundMessage: '',
            logoToCheck: null,
            bcgToCheck: null,
            apkToCheck: null,
            screenshotsToCheck: null,
            rating: null
        }
    },
    created() {
        this.globalLogger('CREATED')
        this.fetchCategories()
        if (this.editEnabled) {
            this.fetchScreenshotsByAppId(this.appToEdit._id)
        }
    },
    mounted() {
        if (this.editEnabled) {
            this.name = this.appToEdit.name
            this.description = this.appToEdit.desc
            this.packageName = this.appToEdit.packageName
            this.appVersion = this.appToEdit.version
            this.checkedCategories = this.appToEdit.categoriesIdList
            this.logoUrl = this.appToEdit.logoImageUrl
            var logoUrlArray = this.logoUrl.split('/')
            this.logoName = logoUrlArray[logoUrlArray.length-1]
            this.bcgUrl = this.appToEdit.backgroundImageUrl
            var bcgUrlArray = this.bcgUrl.split('/')
            this.bcgName = bcgUrlArray[bcgUrlArray.length-1]
            this.apkUrl = this.appToEdit.apkUrl
            var apkUrlArray = this.apkUrl.split('/')
            this.apkName = apkUrlArray[apkUrlArray.length-1]
            this.rating = this.appToEdit.rating
        }
    },
    computed: mapGetters(['allCategories', 'screenshotsByApp', 'screenshotsNumber']),
    methods: {
        ...mapActions(['fetchCategories', 'addApplication', 'editApplication', 'fetchScreenshotsByAppId', 'removedScreenshot']),
        apkUploaded (e) {
            let file = e.target.files[0]
            this.apkToCheck = file
            this.globalLogger(e.target.files, 'files1')
            const formData = new FormData()
            formData.append('file', file)
            this.apkFile = formData
            // this.uploadApk(formData)
        },
        logoUploaded (e) {
            let file = e.target.files[0]
            this.logoToCheck = file
            this.globalLogger(e.target.files, 'files2')
            const formData = new FormData()
            formData.append('file', file)
            this.logoFile = formData
            // this.uploadLogo(formData)
        },
        backgroundUploaded (e) {
            let file = e.target.files[0]
            this.bcgToCheck = file
            this.globalLogger(e.target.files, 'files3')
            const formData = new FormData()
            formData.append('file', file)
            this.backgroundFile = formData
            // this.uploadBackground(formData)
        },
        screenshotsUploaded (e) {
            this.globalLogger('here')
            this.screenshotsFiles = []
            let files = e.target.files
            this.screenshotsToCheck = e.target.files
            for (let i = 0; i < files.length; i++) {
                const formData = new FormData()
                formData.append('file', files[i])
                this.screenshotsFiles.push(formData)
            }
        },
        addApk () {
            document.getElementById('apkSelect').click()
        },
        addLogo () {
            document.getElementById('logoSelect').click()
        },
        addBcg () {
            document.getElementById('backgroundSelect').click()
        },
        addApp () {
            this.bNameError = false
            this.descError = false
            this.categoriesError = false
            this.pnError = false
            this.apkError = false
            this.logoError = false
            this.bcgError = false
            this.scrError = false
            var flag = false
            if (this.screenshotsToCheck !== null) {
                for (let i = 0; i < this.screenshotsToCheck.length; i++) {
                    if (this.screenshotsToCheck[i].type !== 'image/jpeg' && this.screenshotsToCheck[i].type !== 'image/png') {
                        flag = true
                    }
                }
            }
            if (this.name === null || this.name === '') {
                this.bNameError = true
            } else if (this.description === null || this.description === '') {
                this.descError = true
            } else if (this.checkedCategories.length === 0) {
                this.categoriesError = true
            } else if (this.packageName === null || this.packageName === '') {
                this.pnError = true
            } else if (this.apkFile === null) {
                this.apkMessage = 'Please upload an apk file.'
                this.apkError = true
            } else if (this.apkToCheck !== null && this.apkToCheck.type !== 'application/vnd.android.package-archive') {
                this.apkMessage = 'Incompatible file format! The supported type is .apk.'
                this.apkError = true
            } else if (this.logoFile === null) {
                this.logoMessage = 'Please upload a logo image.'
                this.logoError = true
            } else if (this.logoToCheck !== null && this.logoToCheck.type !== 'image/jpeg' && this.logoToCheck.type !== 'image/png') {
                this.logoMessage = 'Incompatible file format! The supported types are .jpg and .png.'
                this.logoError = true
            } else if (this.backgroundFile === null) {
                this.backgroundMessage = 'Please upload a background image.'
                this.bcgError = true
            } else if (this.bcgToCheck !== null && this.bcgToCheck.type !== 'image/jpeg' && this.bcgToCheck.type !== 'image/png') {
                this.backgroundMessage = 'Incompatible file format! The supported types are .jpg and .png.'
                this.bcgError = true
            } else if (flag) {
                this.scrError = true
            } else {
                let appToAdd = {
                    name: this.name,
                    desc: this.description, 
                    version: this.appVersion, 
                    packageName: this.packageName,
                    categoriesIdList: this.checkedCategories,
                    apkFormData: this.apkFile,
                    logoFormData: this.logoFile,
                    backgroundFormData: this.backgroundFile,
                    screenshotsFormData: this.screenshotsFiles
                }
                this.addApplication(appToAdd)
                this.globalLogger(this.selectedCat, 'selected cat')
                if (this.selectedCat !== null && this.selectedCat !== '') {
                    this.globalLogger('selected cat not null')
                    this.$emit('setCategory')
                }
                if (this.searchStr !== null && this.searchStr !== '') {
                    this.globalLogger('search str not null')
                    this.$emit('setSearchStr')
                }
                this.$emit('close')
            }
        },
        editApp () {
            this.bNameError = false
            this.descError = false
            this.categoriesError = false
            this.pnError = false
            this.apkError = false
            this.logoError = false
            this.bcgError = false
            this.scrError = false
            var flag = false
            if (this.screenshotsToCheck !== null) {
                for (let i = 0; i < this.screenshotsToCheck.length; i++) {
                    if (this.screenshotsToCheck[i].type !== 'image/jpeg' && this.screenshotsToCheck[i].type !== 'image/png') {
                        flag = true
                    }
                }
            }
            if (this.name === '') {
                this.bNameError = true
            } else if (this.description === '') {
                this.descError = true
            } else if (this.checkedCategories.length === 0) {
                this.categoriesError = true
            } else if (this.packageName === '') {
                this.pnError = true
            } else if (this.apkToCheck !== null && this.apkToCheck.type !== 'application/vnd.android.package-archive') {
                this.apkMessage = 'Incompatible file format! The supported type is .apk.'
                this.apkError = true
            } else if (this.logoToCheck !== null && this.logoToCheck.type !== 'image/jpeg' && this.logoToCheck.type !== 'image/png') {
                this.logoMessage = 'Incompatible file format! The supported types are .jpg and .png.'
                this.logoError = true
            } else if (this.bcgToCheck !== null && this.bcgToCheck.type !== 'image/jpeg' && this.bcgToCheck.type !== 'image/png') {
                this.backgroundMessage = 'Incompatible file format! The supported types are .jpg and .png.'
                this.bcgError = true
            } else if (flag) {
                this.scrError = true
            } else {
                let editedApp = {
                    _id: this.appToEdit._id,
                    name: this.name,
                    desc: this.description, 
                    version: this.appVersion, 
                    packageName: this.packageName,
                    apkUrl: this.apkUrl,
                    logoImageUrl: this.logoUrl,
                    backgroundImageUrl: this.bcgUrl,
                    categoriesIdList: this.checkedCategories,
                    screenshotsToRemove: this.screenshotsToRemove,
                    rating: this.rating
                }
                if (this.apkFile !== null) {
                    editedApp.apkFormData = this.apkFile
                }
                if (this.logoFile !== null) {
                    editedApp.logoFormData = this.logoFile
                }
                if (this.backgroundFile !== null) {
                    editedApp.backgroundFormData = this.backgroundFile
                }
                this.globalLogger(this.screenshotsFiles, 'scr fILESS')
                if (this.screenshotsFiles !== null) {
                    editedApp.screenshotsFormData = this.screenshotsFiles
                }
                this.editApplication(editedApp)
                this.$emit('close')
            }
        },
        removeScreenshot(id) {
            this.screenshotsToRemove.push(id)
            this.removedScreenshot(id)
            // this.fetchedScreenshots = this.fetchedScreenshots.filter(s => s._id !== id)
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

    /* fieldset div {
        display: inline-block;
        width: 25%;  
        padding-left: 4px;
    } */
    .container {
  display: inline-block;
  position: relative;
  vertical-align: top;
  padding-left: 28px;
  padding-right: 25px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 15.5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #eee;
  border: 1px solid rgb(188, 185, 185);
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #6d70f2;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.carousel .item {
  height: 300px;
}

.item img {
    position: absolute;
    top: 0;
    right: 0;
    min-height: 300px;
}

.content {
  position: absolute; /* Position the background text */
  bottom: 0; /* At the bottom. Use top:0 to append it to the top */
  color: #f1f1f1; /* Grey text */
  width: 100%; /* Full width */
  padding: 3px; /* Some padding */
}

.v-appl {
   font-family: 'Nunito', sans-serif
 }
</style>