<template>
  <div>
    <div class="top">
      <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <img src="./assets/innet-white.png" width="120">
      <div class="navbar-item">
        <p><b>App Store Web Admin</b></p>
      </div>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">

      <NavBar v-if="this.getUsername"/>
    </div>
  </nav>
    </div>
    <div v-if="this.getUsername" class="left">
      <SideBar />
    </div>
    <div class="main">
      <router-view/>
    </div>
  </div>
</template>
  
<script>
// import AppsNew from './views/AppsNew';
// import AppsView from './views/AppsView.vue';
import NavBar from './components/NavBar.vue';
import SideBar from './components/SideBar.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
    // AppsNew,
    // AppsView,
    NavBar,
    SideBar
  },
  computed: mapGetters(['getUsername'])
};
</script>

<style scoped>
.top {
  position: absolute;
  left: 0;
  right: 0;
  height: 92px;
}

.left {
  position: absolute;
  left: 12px;
  top: 90px;
  bottom: 0;
  width: 178px;
}

.main {
  position: absolute;
  left: 245px;
  top: 72px;
  right: 0;
  bottom: 0;
}
</style>