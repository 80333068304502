const state = {
    username: null,
    password: null,
    authHeader: null
}

const getters = {
    getUsername: (state) => {
        return state.username
    },
    getPassword: (state) => {
        return state.password
    }
}

const actions = {
    setUsername({commit}, username) {
        // console.log('set username', username)
        commit('setUsrname', username)
    },
    setPassword({commit}, password) {
        // console.log('set pass!', password)
        commit('setPass', password)
    },
    setAuthHeader({commit}, encStr) {
        commit('setHead', encStr)
    }
}

const mutations = {
    setUsrname: (state, username) => (state.username = username),
    setPass: (state, password) => (state.password = password),
    setHead: (state, encStr) => (state.authHeader = encStr)
}

export default {
    state,
    getters,
    actions,
    mutations
}