import axios from 'axios'
// import { ToastProgrammatic as Toast } from 'buefy'
import { LoadingProgrammatic as Loading } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'

const state = {
    categories: []
}

const getters = {
    allCategories: (state) => {
        return state.categories
    },
    categoryName: (state) => (id) => {
        for (let cat in state.categories) {
            if (state.categories[cat]._id === id) {
                return state.categories[cat].name
            }
        }
    }
}

const actions = {
    async fetchCategories({ commit, rootState }) {
        try {
            const response = await axios.get('https://apps.innet.si:8080/categories/getAll', {
                headers: {                         
                    'Authorization': rootState.user.authHeader
                }
            })
            commit('setCategories', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async addCategory({ commit, rootState }, payload) {
        var loading = Loading.open()
        if (payload.formData !== undefined) {
            try {
                const resp = await axios.post('https://apps.innet.si:8080/upload/uploadBackgroundCategory', payload.formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': rootState.user.authHeader
                    }
                })
                // var id = payload._id
                var url = resp.data.url
                // commit('updatedCategoryBackground', { id, url })
                try {
                    const response = await axios.post('https://apps.innet.si:8080/categories/addCategory', {
                        name: payload.name,
                        backgroundUrl: url
                    }, {
                        headers: {
                            'Authorization': rootState.user.authHeader
                        }
                    })
                    commit('newCategory', response.data)
                    loading.close()
                } catch (error) {
                    loading.close()
                    Dialog.alert({
                        title: 'Error',
                        message: 'An error occured while trying to add category.',
                        confirmText: 'Ok!',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa'
                    })
                }
            } catch (error) {
                loading.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to upload image. Category is not added.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            }
        } else {
            try {
                const response = await axios.post('https://apps.innet.si:8080/categories/addCategory', {
                    name: payload.name,
                    backgroundUrl: ''
                }, {
                    headers: {
                        'Authorization': rootState.user.authHeader
                    }
                })
                commit('newCategory', response.data)
                loading.close()
            } catch (error) {
                loading.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to add category.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            }
        }
    },
    async editCategory({commit, rootState}, payload) {
        var loading = Loading.open()
        if (payload.formData !== undefined) {
            try {
                const resp = await axios.post('https://apps.innet.si:8080/upload/uploadBackgroundCategory', payload.formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': rootState.user.authHeader
                    }
                })
                // console.log(resp.data, 'response data')
                // var id = payload._id
                var url = resp.data.url
                // commit('updatedCategoryBackground', { id, url })
                try {
                    const response = await axios.put(`https://apps.innet.si:8080/categories/update?userId=XXXYYYZZZ&id=${payload._id}`, 
                    {
                        name: payload.name,
                        backgroundUrl: url
                    },
                    { 
                        headers: {
                            'Authorization': rootState.user.authHeader
                        }
                    })
                    // console.log(payload.formData, 'form data')
                    commit('updatedCategory', response.data)
                    loading.close()
                } catch (error) {
                    loading.close()
                    Dialog.alert({
                        title: 'Error',
                        message: 'An error occured while trying to edit category.',
                        confirmText: 'Ok!',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa'
                    })
                    // console.log(error)
                }
            } catch (error) {
                loading.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to upload image. Category is not edited.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
                // console.log(error)
            }
        } else {
            try {
                const response = await axios.put(`https://apps.innet.si:8080/categories/update?userId=XXXYYYZZZ&id=${payload._id}`, 
                {
                    name: payload.name,
                    backgroundUrl: payload.backgroundUrl
                }, { 
                    headers: {
                        'Authorization': rootState.user.authHeader
                    }
                })
                commit('updatedCategory', response.data)
                loading.close()
            } catch (error) {
                loading.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to edit category.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
                // console.log(error)
            }
        }
    },
    async deleteCategory({commit, rootState}, id) {
        var loading = Loading.open()
        try {
            await axios.delete(`https://apps.innet.si:8080/categories/delete?userId=XXXYYYZZZ&id=${id}`, {
                headers: {
                    'Authorization': rootState.user.authHeader
                }
            })
            commit('delCategory', id)
            loading.close()
        } catch (error) {
            loading.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to delete category.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    },
}

const mutations = {
    setCategories: (state, categories) => (state.categories = categories),
    newCategory: (state, category) => (state.categories.push(category)),
    delCategory: (state, id) => (state.categories = state.categories.filter(cat => cat._id !== id)),
    updatedCategory: (state, updCategory) => {
        const index = state.categories.findIndex(cat => cat._id === updCategory._id)
        if (index !== -1) {
            state.categories.splice(index, 1, updCategory)
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}