import axios from 'axios'
import { ToastProgrammatic as Toast } from 'buefy'
import { LoadingProgrammatic as Loading } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'

const state = {
    applications: [],
    filteredApplications: [],
    total: null,
    filteredStartIndex: null,
    loadingComponent: null,
    screenshots: [],
    screenshotsLength: null,
    appsByCategory: []
}

const getters = {
    allApplications: (state) => {
        return state.applications
    },
    filteredApplications: (state) => {
        return state.filteredApplications
    },
    totalCount: (state) => {
        return state.total
    },
    filteredArrayStartIndex: (state) => {
        return state.filteredStartIndex
    },
    screenshotsByApp: (state) => {
        return state.screenshots
    },
    appsByCat: (state) => {
        return state.appsByCategory
    },
    screenshotsNumber: (state) => {
        return state.screenshotsLength
    }
}

const actions = {
    async fetchApplications({ commit, rootState }) {
        try {
            const response = await axios.get('https://apps.innet.si:8080/apps/allApps', {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setApplications', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchAppsByCategory({ commit, rootState }, id) {
        // console.log(id, 'ID')
        try {
            const response = await axios.get(`https://apps.innet.si:8080/apps/getAppsByCategory/${id}`, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setAppsByCat', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    async fetchScreenshotsByAppId({ commit, rootState }, appId) {
        try {
            const response = await axios.get(`https://apps.innet.si:8080/screenshots/getScreenshotsByApp/${appId}`, {
                headers: { 'Authorization': rootState.user.authHeader }
            })
            commit('setScreenshots', response.data)
        } catch (error) {
            // console.log(error)
        }
    },
    removedScreenshot({commit}, id) {
        commit('removeScr', id)
    },
    setFilteredApps({commit}, filteredApps) {
        commit('setFilteredApplications', filteredApps)
    },
    async addApplication({ commit, rootState }, payload) {
        var appToCommit = null
        try {
            var loadingComponent = Loading.open()
            const resp1 = await axios.post('https://apps.innet.si:8080/upload/uploadLogo', payload.logoFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': rootState.user.authHeader
                }
            })
            // var id = payload._id
            var logoUrl = resp1.data.url
            // commit('updatedCategoryBackground', { id, url })
            try {
                const resp2 = await axios.post('https://apps.innet.si:8080/upload/uploadBackground', payload.backgroundFormData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': rootState.user.authHeader
                    }
                })
                var backgroundUrl = resp2.data.url
                // commit('newCategory', response.data)
                try {
                    const resp3 = await axios.post('https://apps.innet.si:8080/upload/uploadApk', payload.apkFormData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': rootState.user.authHeader
                        }
                    })
                    var apkUrl = resp3.data.url
                    var appId = null
                    try {
                        const response = await axios.post('https://apps.innet.si:8080/apps/addApp', {
                            name: payload.name,
                            desc: payload.desc, 
                            version: payload.version,
                            packageName: payload.packageName,
                            categoriesIdList: payload.categoriesIdList,
                            apkUrl: apkUrl,
                            logoImageUrl: logoUrl,
                            backgroundImageUrl: backgroundUrl
                        }, {
                            headers: { 'Authorization': rootState.user.authHeader }
                        })
                        appId = response.data._id
                        if (payload.screenshotsFormData === null) {
                            loadingComponent.close()
                            commit('newApplication', response.data)
                        } else {
                            appToCommit = response.data
                        }
                        // commit('setCat', null)
                    } catch (error) {
                        loadingComponent.close()
                        Dialog.alert({
                            title: 'Error',
                            message: 'An error occured while trying to add app.',
                            confirmText: 'Ok!',
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa'
                        })
                    }
                    if (payload.screenshotsFormData !== null && payload.screenshotsFormData.length > 0) {
                        var urls = []
                        for (let i in payload.screenshotsFormData) {
                            try {
                                const response = await axios.post('https://apps.innet.si:8080/upload/uploadScreenshot', payload.screenshotsFormData[i], {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        'Authorization': rootState.user.authHeader
                                    }
                                })
                                urls.push(response.data.url)
                                // commit('setCat', null)
                            } catch (error) {
                                loadingComponent.close()
                                Dialog.alert({
                                    title: 'Error',
                                    message: 'An error occured while trying to upload a screenshot.',
                                    confirmText: 'Ok!',
                                    type: 'is-danger',
                                    hasIcon: true,
                                    icon: 'times-circle',
                                    iconPack: 'fa'
                                })
                            }
                        }
                        for (let j in urls) {
                            var array = urls[j].split('/')
                            var screenshotPayload = {
                                appId: appId,
                                imageUrl: urls[j],
                                name: array[array.length-1]
                            }
                            try {
                                await axios.post('https://apps.innet.si:8080/screenshots/addScreenshot', screenshotPayload, {
                                    headers: {
                                        'Authorization': rootState.user.authHeader
                                    }
                                })
                                // console.log('inside for za add', response);
                                // commit('setCat', null)
                            } catch (error) {
                                loadingComponent.close()
                                Dialog.alert({
                                    title: 'Error',
                                    message: 'An error occured while trying to associate a screenshot with the app.',
                                    confirmText: 'Ok!',
                                    type: 'is-danger',
                                    hasIcon: true,
                                    icon: 'times-circle',
                                    iconPack: 'fa'
                                })
                            }
                        }
                        loadingComponent.close()
                        commit('newApplication', appToCommit)
                    }
                } catch (error) {
                    loadingComponent.close()
                    Dialog.alert({
                        title: 'Error',
                        message: 'An error occured while trying to upload apk file.',
                        confirmText: 'Ok!',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                        iconPack: 'fa'
                    })
                }
            } catch (error) {
                loadingComponent.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to upload background image.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            }
        } catch (error) {
            loadingComponent.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to upload logo image.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    },
    editApplication({dispatch, rootState}, payload) {
        // console.log(payload.screenshotsToRemove.length, 'payload')
        var req1 = null
        var req2 = null
        var req3 = null
        if (payload.apkFormData !== undefined) {
            req1 = axios.post('https://apps.innet.si:8080/upload/uploadApk', payload.apkFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': rootState.user.authHeader
                }
            })
        }
        if (payload.logoFormData !== undefined) {
            req2 = axios.post('https://apps.innet.si:8080/upload/uploadLogo', payload.logoFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': rootState.user.authHeader
                }
            })
        }
        if (payload.backgroundFormData !== undefined) {
            req3 = axios.post('https://apps.innet.si:8080/upload/uploadBackground', payload.backgroundFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': rootState.user.authHeader
                }
            })
        }
        var editedApp = {
            _id: payload._id,
            name: payload.name,
            desc: payload.desc,
            version: payload.version, 
            packageName: payload.packageName,
            categoriesIdList: payload.categoriesIdList,
            apkUrl: payload.apkUrl,
            logoImageUrl: payload.logoImageUrl,
            backgroundImageUrl: payload.backgroundImageUrl,
            screenshotsToRemove: payload.screenshotsToRemove,
            screenshotsFormData: payload.screenshotsFormData,
            rating: payload.rating
        }
        // console.log('edited app', editedApp)
        state.loadingComponent = Loading.open()
        if (req1 !== null && req2 !== null && req3 !== null) {
            Promise.all([req1, req2, req3]).then(values => {
                // console.log(values, '3 not null')
                editedApp.apkUrl = values[0].data.url
                editedApp.logoImageUrl = values[1].data.url
                editedApp.backgroundImageUrl = values[2].data.url
                dispatch('editApplicationBasic', editedApp)
                // loadingComponent.close()
            })
            // eslint-disable-next-line no-unused-vars
            .catch(function (error) {
                state.loadingComponent.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to upload a resource. App is not edited.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            })
        } else if (req1 !== null && req2 !== null) {
            Promise.all([req1, req2]).then(values => {
                // console.log(values, 'apk i logo not null')
                editedApp.apkUrl = values[0].data.url
                editedApp.logoImageUrl = values[1].data.url
                dispatch('editApplicationBasic', editedApp)
                // loadingComponent.close()
            // eslint-disable-next-line no-unused-vars
            }).catch(function (error) {
                state.loadingComponent.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to upload a resource. App is not edited.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            })
        } else if (req1 !== null && req3 !== null) {
            Promise.all([req1, req3]).then(values => {
                // console.log(values, 'apk i background not null')
                editedApp.apkUrl = values[0].data.url
                editedApp.backgroundImageUrl = values[1].data.url
                dispatch('editApplicationBasic', editedApp)
                // loadingComponent.close()
            // eslint-disable-next-line no-unused-vars
            }).catch(function (error) {
                state.loadingComponent.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to upload a resource. App is not edited.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            })
        } else if (req2 !== null && req3 !== null) {
            Promise.all([req2, req3]).then(values => {
                // console.log(values, 'bcg i logo not null')
                editedApp.logoImageUrl = values[0].data.url
                editedApp.backgroundImageUrl = values[1].data.url
                dispatch('editApplicationBasic', editedApp)
                // loadingComponent.close()
            // eslint-disable-next-line no-unused-vars
            }).catch(function (error) {
                state.loadingComponent.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to upload a resource. App is not edited.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            })
        } else if (req1 !== null) {
            axios.post('https://apps.innet.si:8080/upload/uploadApk', payload.apkFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': rootState.user.authHeader
                }
            })
            .then(function (response) {
                editedApp.apkUrl = response.data.url
                dispatch('editApplicationBasic', editedApp)
                // loadingComponent.close()
            })
            // eslint-disable-next-line no-unused-vars
            .catch(function (error) {
                state.loadingComponent.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to upload apk file. App is not edited.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            });
        } else if (req2 !== null) {
            axios.post('https://apps.innet.si:8080/upload/uploadLogo', payload.logoFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': rootState.user.authHeader
                }
            })
            .then(function (response) {
                editedApp.logoImageUrl = response.data.url
                dispatch('editApplicationBasic', editedApp)
                // loadingComponent.close()
            })
            // eslint-disable-next-line no-unused-vars
            .catch(function (error) {
                state.loadingComponent.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to upload logo image. App is not edited.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            });
        } else if (req3 !== null) {
            axios.post('https://apps.innet.si:8080/upload/uploadBackground', payload.backgroundFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': rootState.user.authHeader
                }
            })
            .then(function (response) {
                editedApp.backgroundImageUrl = response.data.url
                dispatch('editApplicationBasic', editedApp)
                // loadingComponent.close()
            })
            // eslint-disable-next-line no-unused-vars
            .catch(function (error) {
                state.loadingComponent.close()
                Dialog.alert({
                    title: 'Error',
                    message: 'An error occured while trying to upload background image. App is not edited.',
                    confirmText: 'Ok!',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa'
                })
            });
        } else {
            dispatch('editApplicationBasic', editedApp)
        }
    },
    async editApplicationBasic({commit, rootState}, editedApp) {
        // console.log('edit app basic')
        try {
            const response = await axios.put(`https://apps.innet.si:8080/apps/update?userId=XXXYYYZZZ&id=${editedApp._id}`, 
            {
                name: editedApp.name,
                desc: editedApp.desc,
                version: editedApp.version, 
                packageName: editedApp.packageName,
                apkUrl: editedApp.apkUrl,
                logoImageUrl: editedApp.logoImageUrl,
                backgroundImageUrl: editedApp.backgroundImageUrl,
                categoriesIdList: editedApp.categoriesIdList,
                rating: editedApp.rating
            }, {
                headers: {
                    'Authorization': rootState.user.authHeader
                }
            })
            var basicResponse = response.data
            if (editedApp.screenshotsFormData !== null && editedApp.screenshotsFormData !== undefined && editedApp.screenshotsFormData.length > 0 && editedApp.screenshotsToRemove.length > 0) {
                var urls = []
                for (let i in editedApp.screenshotsFormData) {
                    try {
                        const response = await axios.post('https://apps.innet.si:8080/upload/uploadScreenshot', editedApp.screenshotsFormData[i], {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': rootState.user.authHeader
                            }
                        })
                        urls.push(response.data.url)
                    } catch (error) {
                        state.loadingComponent.close()
                        Dialog.alert({
                            title: 'Error',
                            message: 'An error occured while trying to upload a screenshot.',
                            confirmText: 'Ok!',
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa'
                        })
                    }
                }
                // console.log('after for loop', urls)
                for (let j in urls) {
                    var array = urls[j].split('/')
                    var screenshotPayload = {
                        appId: editedApp._id,
                        imageUrl: urls[j],
                        name: array[array.length-1]
                    }
                    try {
                        await axios.post('https://apps.innet.si:8080/screenshots/addScreenshot', screenshotPayload, {
                            headers: {
                                'Authorization': rootState.user.authHeader
                            }
                        })
                    } catch (error) {
                        state.loadingComponent.close()
                        Dialog.alert({
                            title: 'Error',
                            message: 'An error occured while trying to associate a screenshot with the app.',
                            confirmText: 'Ok!',
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa'
                        })
                    }
                }
                for (let scrId in editedApp.screenshotsToRemove) {
                    try {
                        await axios.delete(`https://apps.innet.si:8080/screenshots/delete?userId=XXXYYYZZZ&id=${editedApp.screenshotsToRemove[scrId]}`, {
                            headers: {
                                'Authorization': rootState.user.authHeader
                            }
                        })
                    } catch (error) {
                        state.loadingComponent.close()
                        Dialog.alert({
                            title: 'Error',
                            message: 'An error occured while trying to remove a screenshot.',
                            confirmText: 'Ok!',
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa'
                        })
                    }
                }
                state.loadingComponent.close()
                commit('updatedApplication', basicResponse)
            } else if (editedApp.screenshotsFormData !== null && editedApp.screenshotsFormData !== undefined && editedApp.screenshotsFormData.length > 0) {
                var urls1 = []
                for (let i in editedApp.screenshotsFormData) {
                    try {
                        const response = await axios.post('https://apps.innet.si:8080/upload/uploadScreenshot', editedApp.screenshotsFormData[i], {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': rootState.user.authHeader
                            }
                        })
                        urls1.push(response.data.url)
                    } catch (error) {
                        state.loadingComponent.close()
                        Dialog.alert({
                            title: 'Error',
                            message: 'An error occured while trying to upload a screenshot.',
                            confirmText: 'Ok!',
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa'
                        })
                    }
                }
                for (let j in urls1) {
                    var array1 = urls1[j].split('/')
                    var screenshotPayload1 = {
                        appId: editedApp._id,
                        imageUrl: urls1[j],
                        name: array1[array1.length-1]
                    }
                    try {
                        await axios.post('https://apps.innet.si:8080/screenshots/addScreenshot', screenshotPayload1, {
                            headers: {
                                'Authorization': rootState.user.authHeader
                            }
                        })
                    } catch (error) {
                        state.loadingComponent.close()
                        Dialog.alert({
                            title: 'Error',
                            message: 'An error occured while trying to associate a screenshot with the app.',
                            confirmText: 'Ok!',
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa'
                        })
                    }
                }
                state.loadingComponent.close()
                commit('updatedApplication', basicResponse)
            } else if (editedApp.screenshotsToRemove.length > 0) {
                for (let scrId in editedApp.screenshotsToRemove) {
                    try {
                        await axios.delete(`https://apps.innet.si:8080/screenshots/delete?userId=XXXYYYZZZ&id=${editedApp.screenshotsToRemove[scrId]}`, {
                            headers: {
                                'Authorization': rootState.user.authHeader
                            }
                        })
                        state.loadingComponent.close()
                        commit('updatedApplication', basicResponse)
                    } catch (error) {
                        state.loadingComponent.close()
                        Dialog.alert({
                            title: 'Error',
                            message: 'An error occured while trying to remove a screenshot.',
                            confirmText: 'Ok!',
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            iconPack: 'fa'
                        })
                    }
                }
            } else {
                state.loadingComponent.close()
                commit('updatedApplication', response.data)
            }
        } catch (error) {
            state.loadingComponent.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to edit app.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    },
    async deleteApplication({commit, rootState}, id) {
        var loadingComponent = Loading.open()
        try {
            await axios.delete(`https://apps.innet.si:8080/apps/delete?userId=XXXYYYZZZ&id=${id}`, {
                headers: {
                    'Authorization': rootState.user.authHeader
                }
            })
            loadingComponent.close()
            commit('delApplication', id)
        } catch (error) {
            state.loadingComponent.close()
            Dialog.alert({
                title: 'Error',
                message: 'An error occured while trying to delete application.',
                confirmText: 'Ok!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa'
            })
        }
    },
    setCount({commit}, c) {
        commit('setTotalCount', c)
    },
    setStartIndex({commit}, ind) {
        commit('setStartInd', ind)
    },
    setCategory({commit}, c) {
        commit('setCat', c)
    }
}

const mutations = {
    setApplications: (state, applications) => (state.applications = applications),
    setFilteredApplications: (state, applications) => {
        state.filteredApplications = applications
    },
    newApplication: (state, application) => {
        state.applications.push(application)
        // ako nema search iili kategorija, total e tolku
        state.total = state.applications.length
        if (state.filteredApplications.length < 10) {
            state.filteredApplications.push(application)
        }
        Toast.open({
            duration: 3000,
            message: 'Successfully added application "' + application.name + '"'
        })
        // console.log(state.applications)
    },
    delApplication: (state, id) => {
        let app = state.applications.find(o => o._id === id);
        state.applications = state.applications.filter(app => app._id !== id)
        state.total = state.total - 1
        Toast.open({
            duration: 3000,
            message: 'Successfully deleted application "' + app.name + '"'
        })
        var newFilteredArray = []
        if (state.applications.length > 10) {
            for (let i = 0; i < 10; i++) {
                newFilteredArray.push(state.applications[i])
            }
        } else {
            for (let j = 0; j < state.applications.length; j++) {
                newFilteredArray.push(state.applications[j])
            }
        }
        state.filteredApplications = newFilteredArray
    },
    updatedApplication: (state, updApplication) => {
        const index = state.applications.findIndex(app => app._id === updApplication._id)
        if (index !== -1) {
            state.applications.splice(index, 1, updApplication)
        }
        const index2 = state.filteredApplications.findIndex(app => app._id === updApplication._id)
        if (index2 !== -1) {
            state.filteredApplications.splice(index2, 1, updApplication)
        }
    },
    setTotalCount: (state, count) => (state.total = count),
    setStartInd: (state, index) => (state.filteredStartIndex = index),
    setScreenshots: (state, screenshots) =>  {
        state.screenshots = screenshots
        state.screenshotsLength = screenshots.length
    },
    removeScr: (state, id) => {
        state.screenshots = state.screenshots.filter(s => s._id !== id)
        // console.log(state.screenshots, 'MUTATION')
    },
    setAppsByCat: (state, apps) => (state.appsByCategory = apps)
}

export default {
    state,
    getters,
    actions,
    mutations
}